/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import "materialize-css/dist/css/materialize.min.css"
import "materialize-css/dist/css/materialize.css"

window.axeptioSettings = {
  clientId: "61ec06f4cdeb160d1444972c",
  cookiesVersion: "mvdepinformatique-fr",
}

;(function (d, s) {
  var t = d.getElementsByTagName(s)[0],
    e = d.createElement(s)
  e.async = true
  e.src = "//static.axept.io/sdk.js"
  t.parentNode.insertBefore(e, t)
})(document, "script")
